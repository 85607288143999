import * as React from "react";
import { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import HeroBackground1 from "../../images/hero-background-1.png";
import HeroBackground1Mobile from "../../images/hero-background-1-mobile.png";
import WhatWeLiveFor from "../../images/what-we-live-for.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroBlindAngels from "../../images/homepage-blind-angels-hero.png";
import HeroBlindAngelsMobile from "../../images/homepage-blind-angels-hero-mobile.png";

class HeroCarousel extends Component {
   constructor() {
      super();
      this.state = {
         show: false,
      };
   }
   handleClose = (show) => {
      this.setState({ show: false });
   };
   handleClose = (show) => {
      this.setState({ show: false });
   };
   handleShow = (show) => {
      this.setState({ show: true });
   };
   render() {
      return (
         <Container className="px-0">
            <Row>
               <Col>
                  <Carousel interval={7000} keyboard={true}>

                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/homepage-rapid-start-hero.jpg"}
                           alt="USCHA 2023"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/homepage-rapid-start-hero-mobile.jpg"}
                           alt="USCHA 2023"
                        />

                        <Carousel.Caption className="rapid-start py-0 h-100 align-content-center">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 8, offset: 6 }}
                                    sm={{ span: 6, offset: 7 }}
                                    md={{ span: 6, offset: 8 }}

                                    className="pl-4 px-md-5 pt-md-3"

                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide">
                                          <h2 className="font-heavy line-height-25-mob">
                                             <span className="d-block">Rapid Start <br className="d-block d-lg-none" />Initiation:</span> Starting HIV<br className="d-block d-lg-none" /> Treatment<br className="d-none d-md-block d-xl-block" /> As <br className="d-block d-lg-none" />Soon As Possible
                                          </h2>

                                          <p className="d-none d-lg-block">
                                             Understand Rapid Start, the potential benefits of&nbsp;immediate treatment initiation, and explore a case study on a successful implementation.
                                          </p>


                                          <a
                                             className="slide-anchor open_isi"
                                             href="/landscape/hiv-rapid-start"
                                          >
                                             <span className="btn red text-uppercase">
                                                Learn More
                                             </span>
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/Homepage-NWGHAAD-hero.png"}
                           alt="USCHA 2023"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/Homepage-NWGHAAD-hero-mobile.png"}
                           alt="USCHA 2023"
                        />

                        <Carousel.Caption className="nhtd">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 8 }}
                                    md={{ span: 4, offset: 1 }}
                                    className="pl-2 px-md-0"

                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide">
                                          <h2 className="font-heavy line-height-25-mob text-uppercase">
                                             NATIONAL HIV Testing day is <span className="no-break">june 27</span>
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             In honor of National HIV Testing Day, explore and download our free
                                             social media toolkit – including a carousel of several graphics you
                                             can share on social media to encourage HIV testing.
                                          </p>

                                          <a
                                             className="slide-anchor open_isi"
                                             href="/community-involvement/social-toolkit"
                                          >
                                             <span className="btn red text-uppercase">
                                                Download Free Graphics
                                             </span>
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>


                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/putting-into-practice-hero.png"}
                           alt="USCHA 2023"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/putting-into-practice-hero-mobile.png"}
                           alt="USCHA 2023"
                        />

                        <Carousel.Caption className="prep-matters">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 7 }}
                                    sm={{ span: 8 }}
                                    md={{ span: 6, offset: 1 }}

                                    className="pl-2 px-md-0"

                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide">
                                          <h2 className="font-heavy line-height-25-mob d-none d-lg-block">
                                             PrEP Matters: <span className="no-break">A Guide</span> to Advancing Uptake
                                          </h2>
                                          <h2 className="font-heavy line-height-25-mob d-lg-none">
                                             PrEP Matters: A Guide to Advancing Uptake for Allied Healthcare Professionals
                                          </h2>

                                          <p className="d-none d-lg-block">
                                             Explore this downloadable resource for allied healthcare professionals on advancing <span className="no-break">pre-exposure</span> prophylaxis (PrEP) uptake at the moment of HIV and STI testing.
                                          </p>


                                          <a
                                             className="slide-anchor open_isi"
                                             href="/downloadable-resource/prep-basics"
                                          >
                                             <span className="btn red text-uppercase">
                                                Learn More
                                             </span>
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/hall-of-femme-hero-desktop.jpg"}
                           alt="Hall of Femme"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/hall-of-femme-hero-mobile.jpg"}
                           alt="Hall of Femme"
                        />
                        <Carousel.Caption className="slide-2">
                           <Container className="h-100">
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 12, offset: 0 }}
                                    md={{ span: 5, offset: 8 }}
                                    className="px-0"
                                 >
                                    <a
                                       className="no-interstitial nodecor"
                                       href="../../championsofchange/"
                                    >
                                       <div className="cont-slide">
                                          <h2 className="font-heavy line-height-25-mob text-uppercase">
                                             Champions of Change: A Celebration of Black Women
                                             Changemakers in&nbsp;HIV
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             Gilead is proud to spotlight 10 Black women leaders in HIV. This provides a much-needed spotlight on the monumental work these visionaries are doing to change the
                                             course of the HIV epidemic.
                                          </p>
                                          <a
                                             className="slide-anchor open_isi"
                                             href="/championsofchange/"
                                          >
                                             {/*<span className="btn red text-uppercase d-none d-sm-inline">
                                Learn More <br className="d-block d-md-none"/>
                              </span>*/}
                                             <span className="btn red text-uppercase">
                                                Meet the Leaders
                                             </span>
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                  </Carousel>
               </Col>
            </Row>
         </Container>
      );
   }
}

export default HeroCarousel;
