import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Featured = () => {
   return (
      <Container className='section mt-2 pt-1'>
         <Row>
            <Col xs={10} className='m-auto '>
               <h2 className='title'>Featured topics</h2>
            </Col>
         </Row>
         <Row>
            <Col xs={10} className='m-auto'>
               <Container>
                  <Row className='justify-content-lg-between'>

                     <Col xs={12} lg={4} className='light-grey featured-topic mb-3 mb-lg-0'>
                        <a className='home-card' href='/landscape/hiv-rapid-start'>
                           <h3>Rapid Start Initiation: Starting HIV Treatment As Soon As Possible</h3>
                           <p className='first-module-desktop'>
                              Discover why Rapid Start initiation is recommended by the US Department of Health and Human Services. Also, explore a case study on how Rapid Start is implemented in a Latinx community in Southern Texas.
                           </p>
                           <span className='read-more '>
                              <span className='font-heavy'>Learn more</span>
                              &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                           </span>
                        </a>
                     </Col>

                     <Col xs={12} lg={4} className='light-grey featured-topic mb-3 mb-lg-0'>
                        <a className='home-card' href='/deeper-dive/kayla-quimbley-video/'>
                           <h3>
                              From Poetry to Presidents
                           </h3>
                           <p className='kayla-quimbley'>
                              Kayla Quimbley—the youngest member of the Presidential Advisory Council on HIV/AIDS—is shifting perspectives on what it means to live with HIV. Check out a video of her live performance of her poem, "Thriving with HIV," from Gilead's plenary session at USCHA 2023.

                           </p>
                           <span className='read-more '>
                              <span className='font-heavy' href='/kayla-quimbley' >
                                 Watch video
                              </span>
                              &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                           </span>
                        </a>
                     </Col>

                     <Col xs={12} lg={4} className='light-grey featured-topic mb-3 mb-lg-0'>
                        <a
                           className='home-card'
                           href='/championsofchange'
                        >
                           <h3>Champions of Change: A Celebration of Black Women Changemakers in HIV</h3>
                           <p
                                        /* style={{ marginTop: "-22px", fontSize: "20px" }} */ className='third-module-desktop'
                           >
                              A tribute celebrating 10 inspirational Black women leaders in the HIV movement whose trailblazing work has contributed to changing the course of the HIV epidemic and paved the way for future leaders.
                           </p>
                           <span className='read-more '>
                              <span
                                 className='font-heavy'
                                 href='/championsofchange'
                              >
                                 Learn more
                              </span>
                              &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                           </span>
                        </a>
                     </Col>


                  </Row>
                  <Row>
                     <a href='/deeper-dive/content-index/' className='see-more mt-4'>
                        <span className='font-heavy'>See more content</span>{' '}
                        <i className='fa fa-chevron-right '></i>
                     </a>
                  </Row>
               </Container>
            </Col>
         </Row>
      </Container>
   );
};

export default Featured;
